import { createContext, useContext, useReducer, FC, Dispatch, useMemo } from 'react';

import { Experiment } from 'src/utils/convert';

/**
 * Each top level key should be a unique identifier for an experiment. The value of the key should be
 * used for data that is required to support operations in the ABTestExperiment class
 *
 * @param state ABTestState
 * @param action ABTestActions
 * @returns {ABTestState}
 */
const abTestReducer = (state: ABTestState, action: ABTestActions) => {
  switch (action.type) {
    case SET_TEST_VARIANT_ACTION_TYPE:
      return {
        ...state,
        [action.payload.testName]: action.payload.variant,
      };
    default:
      return state;
  }
};

/**
 * Defines an ABTestExperiment that should encapsulate behaviors related to a specific experiment
 * Examples of this behavior would be:
 *  determining if a specific variant is active or not
 *  activating an experiment via an activation event etc.
 *
 */
class ABTestExperiment {
  name: string;
  variant: string | null;
  constructor(name: string, variant: string | null) {
    this.name = name;
    this.variant = variant;
  }
  getVariant() {
    return this.variant;
  }
  isVariantActive(variant: string) {
    return this.variant === variant;
  }
}

/**
 * Should be used to encapsulate logic for many experiments
 */
const ABTestService = {
  // TODO: add experiments from google-optimize constants
  getDefaultExperimentVariantObject: () => {
    const initialObj: { [key: string]: null } & { chatFlow: null } = { chatFlow: null };

    for (const key of Object.keys(Experiment)) {
      const experimentKey = key as keyof typeof Experiment;
      initialObj[Experiment[experimentKey]] = null;
    }
    return initialObj;
  },
};

const ABTestExperimentContext = createContext<ABTestState>(
  ABTestService.getDefaultExperimentVariantObject()
);
const ABTestDispatchContext = createContext<Dispatch<ABTestActions> | null>(null);

/**
 * Returns the full ABTestSTate
 * @returns {ABTestState}
 */
export const useABTestState = (): ABTestState => useContext(ABTestExperimentContext);
/**
 * Returns the an ABTestExperiment object or null if it does not exist
 * @param experimentName {string}
 * @returns {ABTestExperiment | null}
 */
export const useABTestExperiment = (experimentName: string): ABTestExperiment | null => {
  const abTestState = useABTestState();

  return useMemo(() => {
    const experimentData = abTestState[experimentName];
    if (!experimentData) {
      return null;
    }
    return new ABTestExperiment(experimentName, experimentData);
  }, [experimentName, abTestState]);
};
/**
 * Returns a dispatch function for updating/interacting with the ABTestState
 * @returns {Dispatch<ABTestActions> | null}
 */
export const useABTestDispatcher = (): Dispatch<ABTestActions> | null =>
  useContext(ABTestDispatchContext);

export const ABTestProvider: FC<{ value?: Record<string, string | null> }> = ({
  children,
  value = {},
}) => {
  const [abTests, dispatch] = useReducer(abTestReducer, {
    ...ABTestService.getDefaultExperimentVariantObject(),
    ...value,
  });
  return (
    <ABTestExperimentContext.Provider value={abTests}>
      <ABTestDispatchContext.Provider value={dispatch}>{children}</ABTestDispatchContext.Provider>
    </ABTestExperimentContext.Provider>
  );
};

type ABTestState = {
  chatFlow: string | null;
  [experimentName: string]: string | null;
};

type SetTestVariantAction = {
  type: string;
  payload: {
    variant: string | null;
    testName: string;
  };
};
type ABTestActions = SetTestVariantAction;
const SET_TEST_VARIANT_ACTION_TYPE = 'abTest/setTestVariant';

export const setTestVariant = (testName: string, variant: string | null | undefined) => ({
  type: SET_TEST_VARIANT_ACTION_TYPE,
  payload: {
    variant: variant ?? null,
    testName,
  },
});
