import dynamic from 'next/dynamic';
import { Component, ReactNode } from 'react';
import classNames from 'classnames';
import { withRouter } from 'next/router';
import { WithRouterProps } from 'next/dist/client/with-router';

import dayjs from 'dayjs';
import 'dayjs/locale/ja';

import { setHeaders } from 'src/http-client';
import { DEFAULT_GA_CUSTOM_DIMENSION_VALUE, DIMENSION_INDEX, logPageView } from 'utils/analytics';
import { getItem as getSSItem, removeItem as removeSSItem } from 'utils/sessionStorage';
import { setItem } from 'utils/webStorage';
import { isMobile } from 'utils/layout';
import { utmParamPairsForSimplifiedLayout as utmParamPairs } from 'utils/constant';
import { parseRequestFormData } from 'src/hooks/useRequestFormData';
import { SEPARATE_REQUEST_FORM_PATH } from 'constants/request-form';
import { ABTestProvider } from 'src/context/ab-test';

import type { SeoObject } from 'modules/marketing-pages/marketing-page.types';
import { CustomHead, Footer, Header } from './components';
import { ReduxProps } from './Layout.container';

import styles from './Layout.module.scss';

const DEFAULT_SEO = {
  description:
    'Spend time on what matters most. No more pointless searching. Negotiate until you are satisfied. No sales phone calls. Pick and choose at zero cost. | Zehitomoはカメラマンやパーソナルトレーナーなどの、その道のプロに仕事を依頼できるマッチングサービスです。',
  ogDescription:
    'Hire local service professionals. Fast. Easy. Get free quotes at Zehitomo.com | 身近なプロに仕事を頼もう。手順は簡単、今すぐ Zehitomo.com で無料見積もりを。',
  ogImage: 'https://res.cloudinary.com/zehitomo/image/upload/v1652747398/assets/openGraphLogo.png',
  ogTitle: 'ゼヒトモ',
};

const Loader = dynamic(
  () => import(/* webpackChunkName: "loader" */ 'modules/layout/components/Loader')
);
const NewRequestModal = dynamic(
  () => import(/* webpackChunkName: "forms" */ 'modules/requests/components/NewRequestModal'),
  { ssr: false }
);

function saveUtm() {
  if (typeof window !== 'object') return;

  const { search } = window.location;
  if (!search.includes('utm_')) return;

  const utm = search
    .substring(1)
    .split('&')
    .reduce((result, str) => {
      if (str.startsWith('utm_')) {
        const pair = str.split('=');

        return {
          ...result,
          [decodeURIComponent(pair[0])]: decodeURIComponent(pair[1]),
        };
      }

      return result;
    }, {});
  setItem('ngStorage-utm', JSON.stringify(utm));
}

interface OwnProps {
  abTestInitialState?: Record<string, string | null>;
  children: ReactNode;
  forceHideRequestForm?: boolean;
  hasFooter?: boolean;
  hasHeader?: boolean;
  hasHeaderMenu?: boolean;
  hasHrefLang?: boolean;
  language: string;
  ldJson?: string;
  seo?: SeoObject;
  shouldIncludeUrlParams?: boolean;
  theme?: 'beauty';
  title?: string;
}

interface State {
  pageTitle: string;
}

type Props = OwnProps & ReduxProps & WithRouterProps;

class Layout extends Component<Props, State> {
  static defaultProps = {
    hasFooter: true,
    hasHeaderMenu: true,
    hasHeader: true,
    hasHrefLang: true,
    ldJson: '',
    shouldIncludeUrlParams: false,
    showingRequestModal: false,
    title:
      'あなたの毎日に、プロからのアイディアを。 | ゼヒトモ - Connect with the best Local Service Professionals | Zehitomo',
  };

  constructor(props: Props) {
    super(props);

    // @ts-expect-error setHeaders is not yet typed
    setHeaders({ language: props.language });

    this.handleRouteChangeStart = this.handleRouteChangeStart.bind(this);
    this.handleRouteChangeComplete = this.handleRouteChangeComplete.bind(this);
    this.state = { pageTitle: props.title ?? '' };
  }

  getPrefectureFromRequestFormCookie() {
    const { router } = this.props;
    // IMPORTANT: this cookie should only be consumed on the separate rf page otherwise we should
    // be pulling the prefecture from the URL. This prevents attributing a prefecture to a page
    // without a prefecture in the URL on any page above prefecture
    if (!router.asPath.includes(SEPARATE_REQUEST_FORM_PATH)) {
      return null;
    }
    const cookie = parseRequestFormData();
    if (!cookie) return null;
    return cookie.requestFormContext.prefecture?.key ?? null;
  }

  componentDidMount() {
    const { language, router, setLoading } = this.props;

    // @ts-expect-error FIXME: we shouldn't extend the window object
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (window && !window.LAYOUT_MOUNTED_FIRST_TIME) {
      logPageView({
        [`dimension${DIMENSION_INDEX.jobTypeId}`]:
          router.query.jobTypeId ?? DEFAULT_GA_CUSTOM_DIMENSION_VALUE,
        [`dimension${DIMENSION_INDEX.prefectureKey}`]:
          router.query.prefectureId ??
          this.getPrefectureFromRequestFormCookie() ??
          DEFAULT_GA_CUSTOM_DIMENSION_VALUE,
      });
      // @ts-expect-error we shouldn't extend the window object
      window.LAYOUT_MOUNTED_FIRST_TIME = true;
    }

    dayjs.locale(language);

    router.events.on('routeChangeStart', this.handleRouteChangeStart);
    router.events.on('routeChangeComplete', this.handleRouteChangeComplete);
    // FIXME: fix route change errors
    // The route error change event is quite hard to track. It will take extra effort to track and find exactly what is the cause.
    // So this temporary fix that has been done is actually an efficient and good fix.
    // Another alternative is to completely replace the SPA page navigation with full reload. But this idea still need to be investigated to see the potential impact
    // Reference : https://zehitomo.atlassian.net/browse/PROD-416?focusedCommentId=24608
    router.events.on('routeChangeError', () => {
      setLoading(false);
    });

    saveUtm();

    setLoading(false);
  }

  handleRouteChangeStart() {
    const { setLoading } = this.props;
    setLoading(true);
  }

  handleRouteChangeComplete() {
    const { listAnnouncements, listNotifications, router, setLoading } = this.props;

    logPageView({
      [`dimension${DIMENSION_INDEX.jobTypeId}`]: router.query.jobTypeId,
      [`dimension${DIMENSION_INDEX.prefectureKey}`]: router.query.prefectureId,
    });
    setLoading(false);
    listAnnouncements();
    listNotifications();
  }

  componentDidUpdate(prevProps: Props) {
    const { router, title } = this.props;
    try {
      if (isMobile(window.screen.width)) {
        const closingModal = getSSItem('closingModal');
        if (router.query.showModal === 'false' && closingModal === 'true') {
          const scrollTop = getSSItem('scrollTop');

          if (scrollTop) {
            window.scrollTo(0, Number(scrollTop));
            removeSSItem('closingModal');
            removeSSItem('scrollTop');
          }
        }
      }

      if (prevProps.title !== title) {
        this.setState({ pageTitle: title ?? '' });
      }
    } catch (error) {
      // ignore error
    }
  }

  componentWillUnmount() {
    const { router } = this.props;
    router.events.off('routeChangeStart', this.handleRouteChangeStart);
    router.events.off('routeChangeComplete', this.handleRouteChangeComplete);
  }

  getSeoObject(): SeoObject {
    const { language, seo } = this.props;

    return {
      canonicalUrl: seo?.canonicalUrl,
      description:
        typeof seo?.description === 'object'
          ? seo.description[language]
          : seo?.description ?? DEFAULT_SEO.description,
      keywords: typeof seo?.keywords === 'object' ? seo.keywords[language] : seo?.keywords,
      noFollow: seo?.noFollow,
      noIndex: seo?.noIndex,
      ogDescription:
        typeof seo?.ogDescription === 'object'
          ? seo.ogDescription[language]
          : seo?.ogDescription ?? DEFAULT_SEO.ogDescription,
      ogImage:
        typeof seo?.ogImage === 'object'
          ? seo.ogImage[language]
          : seo?.ogImage ?? DEFAULT_SEO.ogImage,
      ogTitle:
        typeof seo?.ogTitle === 'object'
          ? seo.ogTitle[language]
          : seo?.ogTitle ?? DEFAULT_SEO.ogTitle,
      showTwitterLargeThumbnail: seo?.showTwitterLargeThumbnail,
    };
  }

  render() {
    const {
      abTestInitialState,
      children,
      hasHeader = true,
      hasHeaderMenu = true,
      hasHrefLang = true,
      ldJson = '',
      loading,
      router,
      shouldIncludeUrlParams = false,
      showingRequestModal = false,
      theme,
      forceHideRequestForm,
    } = this.props;

    let { hasFooter = true } = this.props;
    const { pageTitle } = this.state;

    const seo = this.getSeoObject();

    const { asPath: url } = router;
    const { category1, category2, category3, utm_medium, utm_source } = router.query;

    const utmParameter = {
      /* eslint-disable camelcase */
      utmMedium: utm_medium,
      utmSource: utm_source,
      /* eslint-enable camelcase */
    };

    // Check when category1 category2 and category3 exist for normal vertical + category page
    // Check when category1 and category3 does not exist but category2 exist = This is special case for events categories
    if ((category1 && category2 && category3) || (!category1 && category2 && !category3)) {
      hasFooter = !utmParamPairs.some(
        (pair) =>
          pair.utmMedium === utmParameter.utmMedium && pair.utmSource === utmParameter.utmSource
      );
    }

    return (
      <div
        className={classNames(styles.container, {
          'theme-beauty': theme === 'beauty',
        })}
      >
        <ABTestProvider value={abTestInitialState}>
          <CustomHead
            hasHrefLang={hasHrefLang}
            ldJson={ldJson}
            seo={seo}
            shouldIncludeUrlParams={shouldIncludeUrlParams}
            title={pageTitle}
            url={url}
          />
          {hasHeader && <Header hasMenu={hasHeaderMenu} />}

          <main className={styles.content}>{children}</main>

          {hasFooter && <Footer />}

          {loading && <Loader fixed={true} overlay={true} />}

          {/*
          forceHideRequestForm overrides showingRequestModal to always NewRequestModal in the start request page
          This is to avoid having multiple request forms at once in case showingRequestModal is set to true
          from another layout down the line
         */}
          {!forceHideRequestForm && showingRequestModal && <NewRequestModal url={url} />}
        </ABTestProvider>
      </div>
    );
  }
}

export default withRouter(Layout);
